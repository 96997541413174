<template>
  <div class="item-wrap" v-for="(item, index) in list" :key="index" @click="itemHandle(item)">
    <div>{{ item.name }}</div>
    <Icon name="arrow" color="#999999" size="20" />
  </div>
</template>

<script>
import { Icon } from 'vant'
import { useRouter } from 'vue-router'
import { reactive } from 'vue'
export default {
  components: {
    Icon
  },
  setup () {
    // document.title = '账号信息编辑'
    const list = reactive([
      { name: '账号信息', to: '/account' },
      { name: '注册手机号', to: '/mobile' },
      { name: '登录密码', to: '/password' }
    ])
    const router = useRouter()

    const itemHandle = (item) => {
      if (item.to) {
        router.push(item.to)
      }
    }

    return {
      list,
      itemHandle
    }
  }
}
</script>

<style lang="less" scoped>
.item-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px 0 18px;
  font-size: 16px;
  color: #333333;
  height: 48px;
  background: #fff;
  position: relative;
  &::after {
    content: '';
    position: absolute;
    box-sizing: border-box;
    content: ' ';
    pointer-events: none;
    right: 0;
    bottom: 0;
    left: 18px;
    border-bottom: 1px solid #ebedf0;
    transform: scaleY(.5);
  }
}
</style>
